(function($) {
    'use strict';

    $(".c-hero__buttons").detach().appendTo('.c-hero__content');

    /**
     * Prevent scrolling when offcanvas active
     */
    $('#main-nav-toggle').on('click', function() {
        $('body').toggleClass('stop-scrolling');
        
        // Remove bg and logo from nav if offcanvas active
        $('.c-page-header').toggleClass('no-logo-bg');
    });

    /**
     * Main Nav - Add sub navigation toggles
     */
    $('#menu-nested-pages .menu__item--has-children').append('<span class="menu__item--toggle"></span>');

    /**
     * Register click events on sub navigation toggles
     */
    $('.menu__item--toggle').on('click', function() {
        $(this).parent().toggleClass('is-active');
    });

    /**
     * Expand navigation menu to show active child page
     */

    $('.menu__item--parent').addClass('is-active');


    $('#events_wrap2 .calendar_navigation input').attr('value', 'Go to month');

    /**
     * Change text attribute in events wrap details element.
     */

    $('#events_wrap2 .details1').text('BOOKED');


    /**
     * Add definition list value 
     */

     $(".c-definition-list__value").each(function() {
        var className = $(this).html().toLowerCase();
        $(this).parent().addClass(className);
    });

})(jQuery);